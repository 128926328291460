import {BREAKPOINT_SM, isResponsiveScreen} from '../../abstracts/responsive'

const contentAds = () => {

    const zones = bergfex?.promotionZones || {
        skyscraper: 2,
        promotion: 3,
        content: 46,
        leaderboard: 55,
        mobileBanner31: 57,
    }

    const baseUrl = '//partner.bergfex.at/delivery/jsonp/'

    let mocaLoaded = false

    const initContentAds = () => {
        [...document.getElementsByClassName('partner')].forEach((container) => {
            initContentAd(container)
        })
    }

    const initContentAd = (container) => {
        if (!isElementVisible(container)) {
            return
        }

        // check if mobile placing is available
        if (container.classList.contains('responsive-hidden') && isResponsiveScreen()) {
            return
        }

        let zoneid = parseInt(container.dataset.zoneid)

        if(typeof SDG !== "undefined"){
            SDG.Publisher.getIABVendorConsent(137, function(vendorConsent) {
                if (vendorConsent === false) {
                    switch (zoneid) {
                        // fallbacks for internal only ad zone
                        case zones.content: zoneid = 47; break;
                        case zones.skyscraper: zoneid = 48; break;
                    }
                }
            })
        }

        if (zoneid === zones.skyscraper && (!document.getElementById('sidebar') || getAvailableWidth() < 100)) {
            return
        }

        fetchJsonAd(
          container,
          zoneid,
          getUrlSearchParams(container, zoneid),
          getBaseUrl(container)
        )
    }

    const getBaseUrl = (container) => {
        if (!container.dataset.banner) {
            return baseUrl
        }
        
        return baseUrl + 'direct/'
    }

    const getAvailableWidth = () => {
        return window.innerWidth - document.getElementsByTagName('main')[0].offsetWidth
    }

    const getUrlSearchParams = (container, zoneid) => {
        const url = new URLSearchParams()

        if (container.dataset.banner) {
            url.set('bannerid', container.dataset.banner)
            url.set('zoneid', zoneid)
            url.set('json', '1')
        } else {
            let currentLocation = window.location.href.split("dev")
            url.set('zoneid', zoneid)
            url.set('source', container.dataset.geo)
            url.set('tags', container.dataset.tags)
            url.set('loc', currentLocation.length > 1 ? currentLocation[0] + "www" + currentLocation[1] : currentLocation[0])
            url.set('ts', new Date().getTime().toString())
            url.set('isAjax', '1')

            if (zoneid === zones.skyscraper) {
                url.set('freewidth', "" + getAvailableWidth())
            } else if (zoneid === zones.content) {
                url.set('mobile', (isResponsiveScreen(BREAKPOINT_SM) ? '1' : '0'))
            }
        }

        return url
    }

    const isElementVisible = (element) => {
        return element.offsetWidth ||
            element.offsetHeight ||
            element.getClientRects().length
    }

    const fetchJsonAd = (container, zoneid, url, base) => {
        const scriptId = `adzone_${Date.now()}_${Math.ceil(Math.random() * 100000)}`
        return new Promise(() => {
            url = base + '?' + url.toString()
            url += "&jsoncallback=bergfex.contentAds.displayAd('" + scriptId + "')"

            const jsonpScript = document.createElement('script')
            jsonpScript.async = true
            jsonpScript.setAttribute('src', url)
            jsonpScript.setAttribute('id', scriptId)

            document.getElementsByTagName('head')[0].appendChild(jsonpScript)
            container.setAttribute('data-scriptid', scriptId)
            container.classList.add("isLoading")
            jsonpScript.onerror = () => cleanUpAfterFetching(scriptId)
        }).catch(() => cleanUpAfterFetching(scriptId))
    }

    const cleanUpAfterFetching = (scriptId) => {
        document.getElementById(scriptId)?.remove()
        document.querySelector("[data-scriptid='" + scriptId + "']")?.removeAttribute('data-scriptid')
    }

    /**
     * callback function of fetchJsonAd with fetching data with jsonp
     * @param scriptId
     * @returns {(function(*): (undefined|void))|*}
     */
    const displayAd = (scriptId) => {
        return function (banner) {
            let container = document.querySelector("[data-scriptid='" + scriptId + "']")
            cleanUpAfterFetching(scriptId)

            if (!receivedBanner(banner)) {
                if (parseInt(container.dataset.zoneid) !== zones.content) {
                    return
                }

                return getPromotionZone(container)
            }

            if (typeof googletag != 'undefined') {
                googletag.cmd.push(function () {
                    googletag.destroySlots()
                })
            }

            if (!isBannerMocaFirst(banner) || mocaLoaded) {
                return showBannerHTML(container, banner)
            }

            const jsonpScript = document.createElement('script')
            jsonpScript.setAttribute('src', 'https://mocafirst.at/moca.php?id=1000024')
            jsonpScript.async = true
            document.getElementsByTagName('head')[0].appendChild(jsonpScript)

            jsonpScript.onload = () => {
                mocaLoaded = true
                showBannerHTML(container, banner)
                jsonpScript.remove()
            }
        }
    }

    const showBannerHTML = (container, banner) => {
        if(isSitebar(banner)){
            container.classList.add("sitebar")
            container.parentElement.classList.add("sitebar")
        }

        const zoneId = container.dataset.zoneid
        container.classList.remove("isLoading")
        container.classList.add("loaded")
        container.style.display = 'block'

        if(forceIframe(banner)){
            const iframe = document.createElement('iframe')
            iframe.setAttribute('src', 'https://partner.bergfex.at/cache/' + banner.bannerid + '.html')
            iframe.style.overflow =  'hidden'
            iframe.style.margin = 'auto'
            iframe.style.height = '100%'
            iframe.style.width = '100%'
            iframe.style.minWidth = banner.width + 'px'
            iframe.style.minHeight = banner.height + 'px'
            container.appendChild(iframe)
        }else{
            container.innerHTML = banner.html
            stripAndExecuteScript(container)

            const iframe = container.querySelectorAll(':scope iframe')

            if(iframe && iframe.length > 0) {
                iframe[0].style.aspectRatio = banner.width + " / " + banner.height
            }
        }

        if(parseInt(zoneId) !== zones.content) { //if not content box ad zone
            return
        }

        setTimeout(function () {
            if(container.getBoundingClientRect().height > 10 && container.childNodes.length > 0){
                return //banner is displayed
            }
            // no banner was displayed, get fallback for content zone
            getPromotionZone(container)
        }, 3000)
    }

    const stripAndExecuteScript = (elm) => {
        Array.from(elm.querySelectorAll("script")).forEach(oldScriptEl => {
            const newScriptEl = document.createElement("script")

            Array.from(oldScriptEl.attributes).forEach(attr => {
                newScriptEl.setAttribute(attr.name, attr.value)
            })

            const scriptText = document.createTextNode(oldScriptEl.innerHTML)
            newScriptEl.appendChild(scriptText)
            oldScriptEl.parentNode.replaceChild(newScriptEl, oldScriptEl)
        })
    }

    /**
     * force iframe usage for non-async adtags
     * @param banner
     * @returns {boolean}
     */
    const forceIframe = (banner) => {
        return banner.name.toLowerCase().indexOf("iframe") !== -1 && !isBannerMocaFirst(banner)
    }

    const isSitebar = (banner) => {
        return banner.name.toLowerCase().indexOf("sitebar") !== -1 || banner.html.indexOf("sitebar") !== -1
    }

    const isBannerMocaFirst = (banner) => {
        return banner.html.indexOf("mocafirst") !== -1
    }

    const receivedBanner = (banner) => {
        return typeof banner.html === "string" &&
            banner.html.length > 0 &&
            typeof banner.name !== undefined
    }

    const getPromotionZone = (container, type = 'sm') => {
        fetchPromotionZone(type).then(data => {
            container.classList.remove("isLoading")

            if(!data?.promotion) {
                container.style.display = 'none'
            }

            container.innerHTML = data?.promotion ?? ''
        })
    }

    const fetchPromotionZone = async (type = 'sm') => {
        let response = await fetch('/ajax/promotion_zone/?type=' + type, {cache: 'no-store'})
        try {
            return await response.json()
        } catch (e) {
            return ''
        }
    }

    document.addEventListener('DOMContentLoaded', () => {
       initContentAds()
    })

    bergfex = bergfex || {}
    bergfex.contentAds = {displayAd, getPromotionZone, initContentAd}
}

export default contentAds
